import React, { useState } from 'react'
import { FaBars } from "react-icons/fa6";
import {Link} from 'react-router-dom'


export default function Header() {
    const [isOpen, setOpen] = useState();
    
    return (
        <>
            <header>
                <div className='top-header'>
                    <div className = 'logo'>
                        <a target="_blank">
                            <img src = './img/ckc_logo1.png' alt='logo'></img>
                        </a>
                    </div>
                    <div className={`menu ${isOpen ? 'active' : ''}`}>
                        <ul className = 'nav'>
                            <li>
                                <a href='/'>Главная</a>
                            </li>
                            <li>
                                <a href={'/' + '#catalogue'}>Каталог</a>
                            </li>
                            <li>
                                <a href='#other'>О нас</a>
                            </li>
                            <li>
                                <a href='#contacts'>Контакты</a>
                            </li>
                        </ul>
                        
                    </div>
                    <FaBars className={`menu-button ${isOpen ? 'active' : ''}`} onClick={()=> setOpen(!isOpen)}  />
                    
                </div>
                <div className = 'presentation'>
                    <div className='presentation-text'>
                        <h2>Осветите свой мир</h2>
                        <p>С нашими потрясающими светильниками!</p>
                    </div>
                    <div className='presentation-img'>
                        <img src='./img/presentation.png'/>
                    </div>
                </div>
                
            </header>
            
        </>
    )
}

import React from 'react'

export default function About() {
    return (
        <div className='about-us' id='other'>
            {/* <div className='about-us-photo'>

            </div> */}
            <div className='about-us-text'>
                <h2>О нас</h2>
                <p>CKC Lightning - это маяк совершенства в области профессионального светового оборудования. Благодаря неуклонному стремлению к инновациям, качеству и удовлетворенности клиентов мы заняли достойную нишу в отрасли, являясь надежным партнером для профессионалов в области освещения по всему миру.</p>
                <p>В CKC Lightning мы понимаем преобразующую силу света. Это не просто освещение, это создание захватывающих впечатлений, создание настроения и усиление эмоций.</p>                
            </div>
            <div className='vertical_bg'>
                
            </div>
    </div>
    )
}

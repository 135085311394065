
import { Route, Routes, BrowserRouter } from "react-router-dom"
import React, { Component } from 'react'
// import MovingHead from "./Pages/MovingHead"
import Main from "./Pages/Main"
import ScrollToTop from "./components/ScrollToTop"


export default class App extends Component {
    constructor(props) {
        super(props)
        this.state = {

            items: [
                {
                    id: 1,
                    title: 'Светильник СТРАЛ 120',
                    img: 'ckc_stral120.jpg',
                    desc: 'Светильник предназначенный для освещения различных мест на открытом воздухе, таких как дороги для автомобилей и пешеходные переходы, а также открытые пространства и строительные площадки.',
                    tth1: 'Световой поток (Лм):',
                    tth1_1: '16800',
                    tth2: 'Мощность (Вт):',
                    tth2_1: '120',
                    tth3: 'Степень защиты:',
                    tth3_1: 'IP65',
                    tth4: 'лм/Вт:',
                    tth4_1: '140',
                    tth5: 'Масса (кг):',
                    tth5_1: '4,20',
                    tth6: 'Габариты (мм):',
                    tth6_1: '710х206х57',
                    category: 'magistral',
                    price: ''
                },
                {
                    id: 2,
                    title: 'Светильник СТРАЛ 100',
                    img: 'ckc_stral100.jpg',
                    desc: 'Светильник предназначенный для освещения различных мест на открытом воздухе, таких как дороги для автомобилей и пешеходные переходы, а также открытые пространства и строительные площадки.',
                    tth1: 'Световой поток (Лм):',
                    tth1_1: '14000',
                    tth2: 'Мощность (Вт):',
                    tth2_1: '100',
                    tth3: 'Степень защиты:',
                    tth3_1: 'IP65',
                    tth4: 'лм/Вт:',
                    tth4_1: '140',
                    tth5: 'Масса (кг): ',
                    tth5_1: '3,70',
                    tth6: 'Габариты (мм):',
                    tth6_1: '620х206х56',
                    category: 'magistral',
                    price: ''
                },
                {
                    id: 3,
                    title: 'Светильник СТРАЛ 70',
                    img: 'ckc_stral70.jpg',
                    desc: 'Светильник предназначенный для освещения различных мест на открытом воздухе, таких как дороги для автомобилей и пешеходные переходы, а также открытые пространства и строительные площадки.',
                    tth1: 'Световой поток (Лм):',
                    tth1_1: '9800',
                    tth2: 'Мощность (Вт):',
                    tth2_1: '70',
                    tth3: 'Степень защиты:',
                    tth3_1: 'IP65',
                    tth4: 'лм/Вт:',
                    tth4_1: '140',
                    tth5: 'Масса (кг):',
                    tth5_1: '3,00',
                    tth6: 'Габариты (мм):',
                    tth6_1: '520х206х55',
                    category: 'magistral',
                    price: ''
                },
                {
                    id: 4,
                    title: 'Светильник СТРАЛ 50',
                    img: 'ckc_stral50.jpg',
                    desc: 'Светильник предназначенный для освещения различных мест на открытом воздухе, таких как дороги для автомобилей и пешеходные переходы, а также открытые пространства и строительные площадки.',
                    tth1: 'Световой поток (Лм):',
                    tth1_1: '7000',
                    tth2: 'Мощность (Вт):',
                    tth2_1: '50',
                    tth3: 'Степень защиты:',
                    tth3_1: 'IP65',
                    tth4: 'лм/Вт:',
                    tth4_1: '140',
                    tth5: 'Масса (кг):',
                    tth5_1: '2,50',
                    tth6: 'Габариты (мм):',
                    tth6_1: '400х206х55',
                    category: 'magistral',
                    price: ''
                },
                {
                    id: 5,
                    title: 'Светильник ТАЙНО 1000',
                    img: 'ckc_tayno1000.jpg',
                    desc: 'Надежное, элегантное и эффективное решение для освещения внутренних помещений, таких как офисы, склады, магазины, административные здания и объекты жилищно-коммунального хозяйства.',
                    tth1: 'Световой поток (Лм):',
                    tth1_1: '4200',
                    tth2: 'Мощность (Вт):',
                    tth2_1: '37',
                    tth3: 'Степень защиты:',
                    tth3_1: 'IP65',
                    tth4: 'лм/Вт:',
                    tth4_1: '113',
                    tth5: 'Масса (кг):',
                    tth5_1: '1,2',
                    tth6: 'Габариты (мм): 1050х130х40',
                    tth6_1: '',
                    category: 'sklad',
                    price: ''
                },
                {
                    id: 6,
                    title: 'Светильник ТАЙНО 500',
                    img: 'ckc_tayno500.jpg',
                    desc: 'Надежное, элегантное и эффективное решение для освещения внутренних помещений, таких как офисы, склады, магазины, административные здания и объекты жилищно-коммунального хозяйства.',
                    tth1: 'Световой поток (Лм):',
                    tth1_1: '4100',
                    tth2: 'Мощность (Вт):',
                    tth2_1: '37',
                    tth3: 'Степень защиты:',
                    tth3_1: 'IP65',
                    tth4: 'лм/Вт:',
                    tth4_1: '116',
                    tth5: 'Масса (кг):',
                    tth5_1: '0,7',
                    tth6: 'Габариты (мм): 525х130х40',
                    tth6_1: '',
                    category: 'sklad',
                    price: ''
                },
                {
                    id: 7,
                    title: 'Светильник ТАЙНО 250',
                    img: 'ckc_tayno250.jpg',
                    desc: 'Надежное, элегантное и эффективное решение для освещения внутренних помещений, таких как офисы, склады, магазины, административные здания и объекты жилищно-коммунального хозяйства.',
                    tth1: 'Световой поток (Лм):',
                    tth1_1: '1650',
                    tth2: 'Мощность (Вт):',
                    tth2_1: '15',
                    tth3: 'Степень защиты:',
                    tth3_1: 'IP65',
                    tth4: 'лм/Вт:',
                    tth4_1: '110',
                    tth5: 'Масса (кг):',
                    tth5_1: '0,3',
                    tth6: 'Габариты (мм):',
                    tth6_1: '250х130х40',
                    category: 'sklad',
                    price: ''
                },
                {
                    id: 9,
                    title: 'Светильник ЛИМА 25',
                    img: 'ckc_lima25.jpg',
                    desc: 'Коллекция светильников представляет собой доступное, простое и надежное решение для освещения внутренних помещений, таких как магазины и офисы, а также для создания фонового освещения и подсветки архитектурных деталей.',
                    tth1: 'Световой поток (Лм):',
                    tth1_1: '14800',
                    tth2: 'Мощность (Вт):',
                    tth2_1: '140',
                    tth3: 'Степень защиты:',
                    tth3_1: 'IP40(65)',
                    tth4: 'лм/Вт:',
                    tth4_1: '106',
                    tth5: 'Масса (кг):',
                    tth5_1: '3,0',
                    tth6: 'Габариты (мм): ',
                    tth6_1: '2500х70х40',
                    category: 'office',
                    price: ''
                },
                {
                    id: 10,
                    title: 'Светильник ЛИМА 20',
                    img: 'ckc_lima20.jpg',
                    desc: 'Коллекция светильников представляет собой доступное, простое и надежное решение для освещения внутренних помещений, таких как магазины и офисы, а также для создания фонового освещения и подсветки архитектурных деталей.',
                    tth1: 'Световой поток (Лм):',
                    tth1_1: '10500',
                    tth2: 'Мощность (Вт):',
                    tth2_1: '96',
                    tth3: 'Степень защиты:',
                    tth3_1: 'IP40(65)',
                    tth4: 'лм/Вт:',
                    tth4_1: '110',
                    tth5: 'Масса (кг):',
                    tth5_1: '2,4',
                    tth6: 'Габариты (мм):',
                    tth6_1: '2010х70х40',
                    category: 'office',
                    price: ''
                },
                {
                    id: 11,
                    title: 'Светильник ЛИМА 10',
                    img: 'ckc_lima10.jpg',
                    desc: 'Коллекция светильников представляет собой доступное, простое и надежное решение для освещения внутренних помещений, таких как магазины и офисы, а также для создания фонового освещения и подсветки архитектурных деталей.',
                    tth1: 'Световой поток (Лм):',
                    tth1_1: '5000',
                    tth2: 'Мощность (Вт):',
                    tth2_1: ' 37',
                    tth3: 'Степень защиты:',
                    tth3_1: 'IP40(65)',
                    tth4: 'лм/Вт:',
                    tth4_1: '135',
                    tth5: 'Масса (кг):',
                    tth5_1: '1,2',
                    tth6: 'Габариты (мм):',
                    tth6_1: '1010х70х40',
                    category: 'office',
                    price: ''
                },
                {
                    id: 12,
                    title: 'Светильник ЛИМА 5',
                    img: 'ckc_lima5.jpg',
                    desc: 'Коллекция светильников представляет собой доступное, простое и надежное решение для освещения внутренних помещений, таких как магазины и офисы, а также для создания фонового освещения и подсветки архитектурных деталей.',
                    tth1: 'Световой поток (Лм):',
                    tth1_1: '3900',
                    tth2: 'Мощность (Вт):',
                    tth2_1: '37',
                    tth3: 'Степень защиты:',
                    tth3_1: 'IP40(65)',
                    tth4: 'лм/Вт:',
                    tth4_1: '105',
                    tth5: 'Масса (кг):',
                    tth5_1: '0,7',
                    tth6: 'Габариты (мм):',
                    tth6_1: '500х70х40',
                    category: 'office',
                    price: ''
                },
            ],
    
    
            group: [
                {
                    id: 1,
                    title: 'Moving Head',
                    img: 'moving_head.jpeg',
                    link: '/moving_head'
                },
                {
                    id: 2,
                    title: 'LED Spotlights',
                    img: 'led_spotlights.jpeg',
                    link: '/led_spotlights'
                },
                {
                    id: 3,
                    title: 'Theater lighting and studio equipment',
                    img: 'theater_lighting.jpeg',
                    link: '/theater_lighting'
                }
            ],
    
        category: ''
        }
        this.addCategory = this.addCategory.bind(this)
    }
    
    render() {
        
        return (
            <BrowserRouter>
                    <Routes>
                        <Route path="*" element={<Main items={this.state.items} onAdd={this.addCategory}/>}/>
                        {/* <Route path="/moving_head" element={<MovingHead items={this.state.items.filter(item => item.category === '/moving_head')}/>}/> */}
                        
                    </Routes>
                    <ScrollToTop />
            </BrowserRouter>
          )

    }

    addCategory(category) {
        this.setState({ category: [category] }, () => {
            console.log(this.state.category)
        })
    }
    
}

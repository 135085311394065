import React, { Component } from 'react'
import Item from './Item'

export class Items extends Component {
      render() {
        
        return (
            <div className='items' id='catalogue'>
                <h2>Каталог</h2>
                <h3>Магистральное</h3>
                <div className = 'items-sharp'>
                    {this.props.items.filter(item => item.category === 'magistral').map(el => (
                        <Item key = {el.id} item = {el}/>
                    ))}
                </div>
                <h3>Производственное и складское</h3>
                <div className = 'items-sharp'>
                    {this.props.items.filter(item => item.category === 'sklad').map(el => (
                        <Item key = {el.id} item = {el}/>
                    ))}
                </div>
                <h3>Офисное и торговое</h3>
                <div className = 'items-sharp'>
                    {this.props.items.filter(item => item.category === 'office').map(el => (
                        <Item key = {el.id} item = {el}/>
                    ))}
                </div>
                
            </div>
        )
    }
}

export default Items
import React, { Component } from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import About from "../components/About"
import Items from "../components/Items"
import Categories from "../components/Сategories"
import Presentation from '../components/Presentation'

export default class Main extends Component {
    
    
    render() {
        return (
        <div className = 'wrapper'>
            <div className = 'container'>
                <Header />
                <About />
                <Items items = {this.props.items}/>
                <Footer />
            </div>
        </div>
        );
    };
}

import React, { forwardRef } from 'react'
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";



export default function Footer() {
    return (
        <footer id = 'contacts'>
                <div className='footer-sharp'>
                    <h2 className='title'>Контакты</h2>
                    <div className='footer-contact'>
                        <div className='footer-contact-line'>
                            <div><FaLocationDot /></div>
                            <div className='footer-contact-h'>Адрес:</div>
                            <div>Москва, Краснопрудная улица, вл9А</div>
                        </div>
                        <div className='footer-contact-line'>
                            <div><FaPhone /></div>
                            <div className='footer-contact-h'>Телефон:</div>
                            <div>+7 (925) 033 50-08</div>
                        </div>
                        <div className='footer-contact-line'>
                            <div><MdEmail /></div>
                            <div className='footer-contact-h'>Email:</div>
                            <div>info@ckclightning.ru</div>
                        </div>
                    </div>
                </div>
        </footer>
    )
}
